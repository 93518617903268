import accountModule from '@/store/modules/accountModule';

const getNameByEmail = (email) => {
  // Validate if email is a non-empty string and formatted correctly
  if (!email || typeof email !== 'string') {
    return "N/A"; // Return "N/A" for invalid email format
  }

 return  accountModule.reassignmentList?.find(
  (user) => user.email.toLowerCase() === email.toLowerCase(),
)?.name || email;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getNameByEmail,
};
