import './router/componentHooks';
import Vue from 'vue';
import installVue from '@/lib/installVue';
import installVueTailwind from '@/lib/installVueTailwind';
import '@/lib/directives/closable';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import router from './router';
import store from './store';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import rules from '@/lib/validationRules';
import TasqDesktopFE from '@/components/base'
import mdiVue from 'mdi-vue/v2'
import mdiIcons from '@/lib/mdiIcons'
import FloatingVue from 'floating-vue'
import tooltipThemes from '@/lib/tooltipThemes'
import * as Sentry from '@sentry/vue';
import { getConfigEnv } from '@/utils/helpers';


Vue.config.ignoredElements = [/^cq-.*$/, /^stx-.*$/];

Vue.config.productionTip = false;
Vue.component('template-placeholder', {
	render: function(createElement) {
		return createElement('template', this.$slots.default)
	}
})
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
// Init Plugins

installVue(Vue);
Vue.use(TasqDesktopFE)
Vue.use(VueTheMask);
installVueTailwind(Vue);
Vue.use(mdiVue, { icons: mdiIcons});
Vue.use(FloatingVue, {themes: tooltipThemes});
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);


const replay =  Sentry.replayIntegration({
  maskAllText: false,
  blockAllMedia: false,
})

Sentry.init({
  Vue,
  dsn: "https://c13392f1098862a51f16d4c5095b9841@o1374117.ingest.us.sentry.io/4507378833686528",
  integrations: [
    replay,
    Sentry.replayCanvasIntegration({
      // Enabling the following will ensure your canvas elements are not forced
      // into `preserveDrawingBuffer`.
      enableManualSnapshot: true,
    }),
  ],
  environment: getConfigEnv('BRANCH_NAME'),
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});




new Vue({
  router,
  store,
  watch: {
    $route: () => {
      // @ts-ignore
      window.Appcues.page();
    },
  },
  render: (h) => h(App),
}).$mount('#app');
