import { Vue } from 'vue-property-decorator';

// This variable will hold the reference to
// document's click handler
let handleOutsideClick;

Vue.directive('closable', {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value;

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          // @ts-ignore
          const excludedEl = vnode.context.$refs[refName];
          if (excludedEl) {
            // @ts-ignore
            clickedOnExcludedEl = excludedEl.contains(e.target);
          }
          // See if this excluded element
          // is the same element the user just clicked on
          // @ts-ignore
        }
      });

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        // @ts-ignore
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },

  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

Vue.directive('longpress', {
  bind(el, binding, vNode) {
    // Make sure expression provided is a function
    if (typeof binding.value !== 'function') {
      // Fetch name of component
        // @ts-ignore
      const compName = vNode.context.name;
      // pass warning to console
      let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) { warn += `Found in component '${compName}' `; }

      console.warn(warn);
    }

    // Define variable
    let pressTimer = null;

    // Define funtion handlers
    // Create timeout ( run function after 1s )
    const start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return;
      }

      if (pressTimer === null) {
          // @ts-ignore
        pressTimer = setTimeout(() => {
          // Run function
          // @ts-ignore
          handler();
        }, 100);
      }
    };

    // Cancel Timeout
    const cancel = (e) => {
      // Check if timer has a value or not
      if (pressTimer !== null) {
        // @ts-ignore
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };
    // Run Function
    const handler = (e) => {
      binding.value(e);
    };

    // Add Event listeners
    el.addEventListener('mousedown', start);
    el.addEventListener('touchstart', start);
    // Cancel timeouts if this events happen
    el.addEventListener('click', cancel);
    el.addEventListener('mouseout', cancel);
    el.addEventListener('touchend', cancel);
    el.addEventListener('touchcancel', cancel);
  },
});


Vue.directive('auto-expand', {
  bind(el, binding, vnode) {
    el.style.overflow = 'hidden';
    el.style.resize = 'none'; // Prevent manual resizing

    function resize() {
      el.style.height = 'auto'; // Reset height to recalculate
      el.style.height = el.scrollHeight > 32 ? el.scrollHeight + 'px' : '32px'; // Set to scroll height
    }

    // Attach the resize function to the input event
    el.addEventListener('input', resize);
    resize(); // Call resize immediately to size correctly initially
  },

  unbind(el) {
    // Remove the event listener when the element is unbound
       // @ts-ignore
    el.removeEventListener('input', el.__resizeHandler__);
  }
});
