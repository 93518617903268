import { Component } from 'vue';
import * as config from '@/config';




const getEnv = (key: string): (string|undefined) => process.env[`VUE_APP_${key.toUpperCase()}`];
const isProd = () => getEnv('env') === 'production';
const getConfigEnv = (key: string): any => config.default[key];
const randomInteger = (min: number, max: number): number => Math.floor((Math.random() * ((max - min) + 1)) + min);
const ucWords = (str: string): string => `${str}`.replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase());
const ucfirst = (str:string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
const jsonParse = (json: string): any => JSON.parse(json);
const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

// Vue related
const getComponent = (name: string): Promise<Component> => import(
  /* webpackChunkName: "components/[request]" */ `@/components/${name}.vue`
);
const getView = (name: string): Promise<Component> => import(
  /* webpackChunkName: "views/[request]" */ `@/views/${name}.vue`
);



// Commonly used methods
function addNumberPadding(n, width, z) {
	let znew = z || '0';
	let nnew = n + '';
	return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function dateDiff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}

function getOffset( el ) {
	var _x = 0;
	var _y = 0;
	while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return { top: _y, left: _x };
}

// getPos
function getElementPosition(el, type) {
	for (var lx=0, ly=0;
		el != null;

		lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
	return {x: lx,y: ly};
}

function getElementCenterPosition(el, type) {
	var width = el.getBoundingClientRect().width;
	var height = el.getBoundingClientRect().height;

	var elPosition = getOffset(el)
	var centerX = elPosition.left + width / 2;
	var centerY = elPosition.top + height / 2;


	return {x: elPosition.left + centerX, y: elPosition.top + centerY};
}

function convertISOtoLocalTime(iso_date) { // iso_date type: '2022-04-20T01:28'
  let date:any = new Date(iso_date + 'Z')
  let local_date = new Date( date.getTime() - ( date.getTimezoneOffset() * 60000 ) );
  return local_date.toISOString().slice(0, 19)
}

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
}


function  isNumberConvertable(value) {
  // Check if the value is already a number.
  if (typeof value === "number") {
    return true;
  }

  // Try to convert the value to a number.
  const convertedValue = Number(value);

  // Check if the converted value is a valid number.
  if (isNaN(convertedValue)) {
    return false;
  }

  // The value can be converted to a number.
  return true;
}

function getRandomUser() {
  const users = [
    "Charles Boom",
    "Rachel Yeah",
    "Donald Duck",
    "Lucy Lu"
  ];

  const randomIndex = Math.floor(Math.random() * users.length);
  return users[randomIndex];
}

// Example usage:




function randomAssetNameForDemo(level, asset_name) {
  // Validate inputs: if level or asset_name is missing, return an empty string
  if(isProd()){
    return asset_name;
  }
  if (!level || !asset_name) {
    return '';
  }


  const url = window.location.href;
  if( url.includes('demo') || url.includes('localhost')){
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];

    switch (level.toLowerCase()) {
      case "well":
        return asset_name.length % 2 == 0
          ? `Well ` + asset_name.substring(0, 2).toUpperCase()
          : `Well ` + asset_name.substring(0, 1).toUpperCase();

      case "pad":
        return asset_name.length % 2 == 0
          ? `Pad ` + asset_name.substring(0, 2).toUpperCase()
          : `Pad ` + asset_name.substring(0, 1).toUpperCase();

      case "route":
        return asset_name.length % 2 == 0
          ? `Route ` + asset_name.substring(0, 2).toUpperCase()
          : `Route ` + asset_name.substring(0, 1).toUpperCase();

      case "team":
        return asset_name.length % 2 == 0
          ? `Team ` + asset_name.substring(0, 2).toUpperCase()
          : `Team ` + asset_name.substring(0, 1).toUpperCase();

      case "area":
        return asset_name.length % 2 == 0
          ? `Area ` + asset_name.substring(0, 2).toUpperCase()
          : `Area ` + asset_name.substring(0, 1).toUpperCase();

      case "user":
        return getRandomUser();

      default:
        return asset_name; // If level doesn't match, return original asset_name
    }
  }

  return asset_name;
}



function getISOStringWithLocalOffset(date) {
  if(!date){
    return date
  }
  const now = new Date();
  const localDate =  date.split('T')
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = function(num) {
    const norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return localDate[0] + 'T' + pad(now.getHours())
    + ':' + pad(now.getMinutes())
    + ':' + pad(now.getSeconds())
    + dif + pad(tzo / 60)
    + ':' + pad(tzo % 60);
}


function isValidJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export {
  addNumberPadding,
  sleep,
  dateDiff,
  getOffset,
  getElementPosition,
  getElementCenterPosition,
  getEnv,
  isProd,
  getConfigEnv,
  randomInteger,
  ucWords,
  ucfirst,
  jsonParse,
  groupBy,
  getComponent,
  getView,
  convertISOtoLocalTime,
  getFormattedDate,
  isValidJsonString,
  isNumberConvertable,
  getISOStringWithLocalOffset,
  randomAssetNameForDemo

};
